<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          ref="resourceTable"
          :title="title"
          :table-headers="tableHeaders"
          :actions-display-method="actionsDisplayMethod"
          :actions-list="actionsList"
          :form-scheme="formScheme"
          :resource="resource"
          :expanded="expanded"
          :show-expand="showExpand"
          :single-expand="singleExpand"
          :hide-top-action-btn="true"
          :custom-opt="customOpt"
          :item-class-fn="itemClassFn"
          :allow-default-actions="allowDefaultActions"
          :extra-table-height="$pageHeight - 60"
          @inputChange="handleChange"
          @dialogChange="handleDialog"
          @changeSelected="handleSelected"
          @changeItems="handleChangeItems"
          @currentItems="handleCurrentItems"
        >
          <template #top-filter>
            <div class="pa-3 pt-0">
              <v-form
                ref="form"
                v-model="filterValid"
                :lazy-validation="true"
                @submit.prevent="() => false"
              >
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="filter.domain"
                      :placeholder="$t('Domain')"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="filter.server"
                      :items="filterItems.servers"
                      item-text="text"
                      item-value="value"
                      :clearable="true"
                      :clear-icon="'$clear'"
                      :label="$t('Server')"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="filter.provider"
                      :items="filterItems.providers"
                      item-text="text"
                      item-value="value"
                      :clearable="true"
                      :clear-icon="'$clear'"
                      :label="$t('Domain provider')"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="filter.cf"
                      :items="filterItems.cf"
                      item-text="text"
                      item-value="value"
                      :clearable="true"
                      :clear-icon="'$clear'"
                      :label="$t('Cloudflare')"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col
                    v-can="'affiliates.teams.list'"
                  >
                    <v-autocomplete
                      v-model="filter.team"
                      :items="filterItems.teams"
                      item-text="text"
                      item-value="value"
                      :clearable="true"
                      :clear-icon="'$clear'"
                      :label="$t('Affiliates teams')"
                      hide-details="auto"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </template>

          <template #top-action-buttons>
            <v-btn
              v-show="
                getStatus === 'draft' &&
                  selected.length < 1 &&
                  $can('domains.management.remove')
              "
              v-can="'domains.management.remove'"
              :color="`error`"
              :loading="removeAllDraftLoading"
              :disabled="removeAllDraftLoading"
              dark
              small
              bottom
              left
              @click="removeAllDraftConfirm"
            >
              {{ $t("Delete all") }}
              <v-icon
                right
                small
              >
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
            <dialog-confirm
              :dialog="removeAllDraftConfirmDialog"
              :title="$t('Are you sure you want to delete all drafts?')"
              :confirm-loading="removeAllDraftLoading"
              :confirm-text="$t('Permanently')"
              @close="removeAllDraftConfirmDialogClose"
              @confirm="removeAllDraft"
            />
            <dialog-confirm
              :dialog="removePermanentlyConfirmDialog"
              :title="$t('Are you sure you want to delete this?')"
              :confirm-loading="removePermanentlyLoading"
              :confirm-text="$t('Permanently')"
              @close="removePermanentlyConfirmClose"
              @confirm="removePermanentlyConfirm"
            />
            <div
              v-show="selected.length > 0"
              v-can="'domains.management.update'"
              class="ml-3"
            >
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="bulkEditingAction"
                    label="Bulk actions list"
                    :items="bulkEditingActionsList"
                    class="mt-6 pa-0"
                    clearable
                  />
                </v-col>
                <v-col
                  v-show="!!bulkEditingAction"
                  cols="6"
                >
                  <div v-if="bulkEditingAction === 'changeIP'">
                    <v-text-field
                      v-model="bulkEditingSelectedParam"
                      :append-icon="'mdi-content-save'"
                      placeholder="10.10.10.10"
                      class="mt-6 pa-0"
                      :rules="[
                        (v) =>
                          /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(
                            v
                          ) || 'IP Address is invalid',
                      ]"
                      hide-details
                      @click:append="bulkEditingConfirm"
                      @keyup.native.enter="bulkEditingConfirm"
                    />
                  </div>
                  <div v-else-if="bulkEditingAction === 'changeNSS'">
                    <v-text-field
                      v-model="bulkEditingSelectedParam"
                      class="mt-6 pa-0"
                      placeholder="ns1.dom.com, ns2.dom.com"
                      :append-icon="'mdi-content-save'"
                      @click:append="bulkEditingConfirm"
                      @keyup.native.enter="bulkEditingConfirm"
                    />
                  </div>
                  <div v-else-if="bulkEditingAction === 'setDefaultNSS'">
                    &nbsp;
                  </div>
                  <div v-else-if="bulkEditingAction === 'checkingPayment'">
                    &nbsp;
                  </div>
                  <div v-else-if="bulkEditingAction === 'moveToTrash'">
                    &nbsp;
                  </div>
                  <div v-else-if="bulkEditingAction === 'moveFromTrash'">
                    &nbsp;
                  </div>
                  <div v-else-if="bulkEditingAction === 'sync'">
                    &nbsp;
                  </div>
                  <div v-else>
                    <v-autocomplete
                      v-model="bulkEditingSelectedParam"
                      label="Select new value"
                      :items="bulkEditingParams"
                      class="mt-6 pa-0"
                      @change="bulkEditingConfirm"
                    />
                  </div>
                </v-col>
              </v-row>
              <dialog-confirm
                :dialog="bulkEditingConfirmDialog"
                :title="$t('domains.bulkActions.bulkActionConfirmTitle')"
                :confirm-loading="getBulkEditingConfirmLoading"
                @close="bulkEditingConfirmDialogClose"
                @confirm="bulkEditing"
              />
            </div>
          </template>
          <template #[`item.name`]="{ item }">
            <div class="pt-2 pb-2">
              <div class="mb-1">
                <div class="mt-1 text-no-wrap">
                  <a
                    :href="'https://' + item.name"
                    target="_blank"
                    @dblclick="openWindow(item.name)"
                    @click.prevent=""
                  >{{ item.name }}</a>

                  <v-btn
                    v-if="
                      ['active', 'draft', 'withErrors'].includes(item.status)
                    "
                    v-can="'domains.management.sync'"
                    class="ml-2"
                    elevation="2"
                    x-small
                    icon
                    :loading="item.syncLoading"
                    @click="syncDomainDns(item)"
                  >
                    <!--v-icon small>mdi-alert-decagram</v-icon-->
                    <v-icon color="grey">
                      mdi-sync
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-can="'domains.management.view'"
                    class="ml-3"
                    elevation="2"
                    x-small
                    icon
                    @click="domainDnsInfo(item)"
                  >
                    <!--v-icon small>mdi-alert-decagram</v-icon-->
                    <v-icon color="grey">
                      mdi-information-variant
                    </v-icon>
                  </v-btn>
                </div>
                <div
                  v-if="
                    !!item.address_record &&
                      item.address_record === item.address_record_real
                  "
                  class="grey--text small mt-2"
                >
                  {{ item.address_record }}
                </div>
                <div
                  v-else
                  class="red--text small mt-2"
                >
                  DNS A-Records not matched
                </div>
                <div
                  v-if="
                    item.ns_records &&
                      $helper.compareTwoArrays(
                        item.ns_records,
                        item.ns_records_real
                      )
                  "
                  class="grey--text small"
                >
                  {{
                    Array.isArray(item.ns_records)
                      ? item.ns_records.join(", ")
                      : ""
                  }}
                </div>
                <div
                  v-else
                  class="red--text small"
                >
                  NSS-Records not matched
                </div>
              </div>
            </div>
          </template>

          <template #[`item.aff.team`]="{ item }">
            <div class="text-no-wrap">
              {{ item.aff.team || "&mdash;" }}
            </div>
            <div class="grey--text small text-no-wrap">
              {{ item.aff.name || "&mdash;" }}
            </div>
          </template>

          <template #dates="{ item }">
            <div class="grey--text small">
              {{ $helper.dateFilter(item.creation_date) || "&mdash;" }} /
              {{ $helper.dateFilter(item.expiration_date) || "&mdash;" }}
            </div>
          </template>

          <template #[`item.provider.name`]="{ item }">
            <div class="text-no-wrap">
              {{ item.provider.name }}
            </div>
            <div class="grey--text small">
              {{ item.provider.login }}
            </div>
          </template>

          <template #[`item.srv.name`]="{ item }">
            <div class="mt-2 mb-2">
              <div class="text-no-wrap">
                {{ item.srv.name }}
              </div>
              <div
                v-show="item.srv.id"
                class="grey--text small"
              >
                <span>Country: {{ item.srv.country || "NONE" }}; </span>
                <span>Ctrl panel: {{ item.srv.with_cp ? "YES" : "NONE" }}; </span>
                <span>Cert: {{ item.srv.ssl ? "YES" : "NO" }}; </span>
                <span>User: {{ item.hosting_server_user || '&mdash;' }};</span>
                <div
                  v-if="
                    item.address_record_real &&
                      item.srv.ip &&
                      !item.srv.ip.includes(item.address_record_real)
                  "
                  class="red--text small mt-2"
                >
                  <div>Domain IP does not match server IP</div>
                  <div class="grey--text small">
                    <span style="text-decoration: line-through">{{
                      item.address_record_real
                    }}</span>
                    |
                    {{ item.srv.ip[0] }}
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template #[`item.ssl`]="{ item }">
            <v-layout justify-center>
              <v-flex
                xs12
                md6
              >
                <v-switch
                  :input-value="
                    item.srv.ssl === true && item.cloudflare_ssl !== 'off'
                  "
                  hide-details
                  disabled
                />
              </v-flex>
            </v-layout>
          </template>

          <template #[`item.cf.email`]="{ item }">
            <div class="text-no-wrap">
              {{ item.cf.name }}
            </div>
            <div class="grey--text small">
              {{
                item.cf.nss !== undefined && item.cf.nss.length > 0
                  ? item.cf.nss.join(", ")
                  : ""
              }}
            </div>
            <div class="grey--text small">
              {{ item.cf.email }}
            </div>
            <div
              v-show="!!item.cloudflare_ssl"
              class="grey--text small"
            >
              {{
                `SSL: ${!!item.cloudflare_ssl ? item.cloudflare_ssl : "off"}`
              }}
            </div>
          </template>

          <template #action-buttons="{ item }">
            <div v-if="item.status === 'deleted'">
              <table-btn
                :item="item"
                :disabled="item.loading"
                :tooltip-text="$t('Undo')"
                :icon="`delete-restore`"
                :can="`${resource}.remove`"
                :fn="undelete"
                :small="false"
              />
              <table-btn
                :item="item"
                :disabled="item.loading"
                :tooltip-text="$t('Permanently')"
                :icon="`delete`"
                :can="`${resource}.remove`"
                :fn="removePermanently"
                :small="false"
              />
            </div>
            <div v-else-if="item.status === 'pendingRegistration'">
              <time-ago :date="item.updatedAt" />
            </div>
            <div v-else-if="item.status === 'pendingSettings'">
              <time-ago :date="item.updatedAt" />
            </div>
          </template>
          <!--          <template #action-buttons="{ item }">-->
          <!--            <v-menu-->
          <!--              bottom-->
          <!--              left-->
          <!--            >-->
          <!--              <template v-slot:activator="{ on, attrs }">-->
          <!--                <v-btn-->
          <!--                  icon-->
          <!--                  v-bind="attrs"-->
          <!--                  v-on="on"-->
          <!--                  :loading="item.loading"-->
          <!--                >-->
          <!--                  <v-icon small>-->
          <!--                    mdi-key-chain-variant-->
          <!--                  </v-icon>-->
          <!--                </v-btn>-->
          <!--              </template>-->

          <!--              <v-list>-->
          <!--                <v-list-item-->
          <!--                  v-show="item.domain_provider_id"-->
          <!--                  v-can="'domains.providers.auth'"-->
          <!--                  @click="getCredentials(item.domain_provider_id, 'domainsProvider')"-->
          <!--                >-->
          <!--                  {{ $t('domains.table.registrar') }}-->
          <!--                </v-list-item>-->
          <!--                <v-list-item-->
          <!--                  v-show="item.cloudflare_id"-->
          <!--                  v-can="'domains.cloudflare.auth'"-->
          <!--                  @click="getCredentials(item.cloudflare_id, 'cloudflare')"-->
          <!--                >-->
          <!--                  {{ $t('domains.table.cloudflare') }}-->
          <!--                </v-list-item>-->
          <!--                <v-list-item-->
          <!--                  v-show="item.hosting_server_id"-->
          <!--                  v-can="'hosting.servers.auth'"-->
          <!--                  @click="getCredentials(item.hosting_server_id, 'hostingServer')"-->
          <!--                >-->
          <!--                  {{ $t('domains.table.hostingServers') }}-->
          <!--                </v-list-item>-->
          <!--              </v-list>-->
          <!--            </v-menu>-->
          <!--            <v-btn-->
          <!--              v-can="'hosting.servers.sync'"-->
          <!--              icon-->
          <!--              @click="sync(item.id)"-->
          <!--            >-->
          <!--              <v-icon small>-->
          <!--                mdi-autorenew-->
          <!--              </v-icon>-->
          <!--            </v-btn>-->
          <!--          </template>-->
        </table-data>

        <dialogs-nameservers
          :dialog="nssDialog"
          :edited-item="nssEditedItem"
          @close="nssDialogClose"
        />
        <dialogs-domains-dns
          :dialog="dnsDialog"
          :edited-item="dnsEditedItem"
          @close="closeDnsDialog"
        />
        <dialogs-cloudflare-zone
          :dialog="cloudflareZoneDialog"
          :edited-item="cloudflareZoneEditedItem"
          :action="'update'"
          @close="closeCloudflareZoneDialog"
        />
        <dialogs-cloudflare-dns
          :dialog="cloudflareDnsDialog"
          :edited-item="cloudflareDnsEditedItem"
          @close="closeCloudflareDnsDialog"
        />
        <dialogs-change-domain-owner
          :dialog="changeDomainOwnerDialog"
          :edited-item="changeDomainOwnerEditedItem"
          @close="changeDomainOwnerDialogClose"
        />
        <dialogs-domain-transfer
          :dialog="domainTransferDialog"
          :edited-item="domainTransferEditedItem"
          @close="domainTransferDialogClose"
        />
      </v-col>
    </v-row>
    <dialog-confirmation ref="confirmationDialog" />
  </v-container>
</template>

<script>
import Vue from 'vue'

import DialogsNameservers from './dialogs/Nameservers'
import DialogsDomainsDns from './dialogs/DomainsDns'
import DialogsCloudflareZone from './dialogs/CloudflareZone'
import DialogsCloudflareDns from './dialogs/CloudflareDns'

import DialogsChangeDomainOwner from '../hosting/dialogs/ChangeDomainOwner'
import DialogsDomainTransfer from '../hosting/dialogs/DomainTransfer'
import TimeAgo from '../../../components/TimeAgo'

export default {
  name: 'Management',
  components: {
    TimeAgo,
    DialogsNameservers,
    DialogsDomainsDns,
    DialogsCloudflareZone,
    DialogsCloudflareDns,
    //
    DialogsChangeDomainOwner,
    //
    DialogsDomainTransfer
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['status'],
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('domains.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'domains.management',
    currentItems: [],
    syncLoading: false,
    editedItem: {},
    expanded: [],
    showExpand: false,
    singleExpand: true,
    actionsDisplayMethod: 'list',
    actionsList: [],
    allowDefaultActions: true,
    // PROVIDER: NSS
    nssDialog: false,
    nssEditedItem: {},
    // PROVIDER: DNS
    dnsDialog: false,
    dnsEditedItem: {},
    // CF: ZONE
    cloudflareZoneDialog: false,
    cloudflareZoneEditedItem: {},
    // CF: DNS
    cloudflareDnsDialog: false,
    cloudflareDnsEditedItem: {},
    // SERVER: CHANGE DOMAIN OWNER
    changeDomainOwnerDialog: false,
    changeDomainOwnerEditedItem: {},
    //
    domainTransferDialog: false,
    domainTransferEditedItem: {},
    //
    selected: {},
    bulkEditingAction: '',
    bulkEditingActionsList: [],
    bulkEditingSelectedParam: '',
    bulkEditingParams: [],
    bulkEditingConfirmDialog: false,
    bulkEditingConfirmLoading: false,
    clearCheckbox: '',
    //
    domainsDetailsStats: [],
    domainNssFromProvider: [],
    domainNssReal: [],
    //
    removeAllDraftConfirmDialog: false,
    removeAllDraftLoading: false,
    //
    removePermanentlyConfirmDialog: false,
    removePermanentlyLoading: false,
    //
    undeleteConfirmDialog: false,
    undeleteLoading: false,
    //
    filterContainer: false,
    filter: {
      domain: '',
      server: '',
      provider: '',
      cf: '',
      team: ''
    },
    filterValid: true,
    filterItems: {
      servers: [],
      providers: [],
      cf: [],
      teams: []
    }
  }),
  computed: {
    getBulkEditingConfirmLoading() {
      return this.bulkEditingConfirmLoading
    },
    getStatusText() {
      return `${this.$helper.capFirstLetter(this.status)}`
    },
    getStatus() {
      return this.status
    },
    customOpt() {
      return {
        filter: this.filter,
        status: this.status
      }
    },
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    }
  },
  watch: {
    status(val) {
      this.title = `${this.$t('domains.title')} — ${this.$helper.capFirstLetter(
        val
      )}`
      this.status = val
      this.initBulkEditing()
    },
    editedItem(val) {
    },
    async 'editedItem.cloudflare_id'(val) {
      if (val) {
        const result = await this.$store.dispatch('cloudflare/getAccountNSS', {
          id: val
        })
        if (result) {
          Vue.set(this.editedItem, 'ns_records', result)
        }
      }
    },
    async 'editedItem.hosting_server_id'(val) {
      await this.formSchemeServer(val)
    },
    async bulkEditingAction(val) {
      switch (val) {
        // case 'changeStatus':
        //   this.bulkEditingParams = [
        //     { text: 'Draft', value: 'draft' },
        //     { text: 'Active', value: 'active' },
        //     { text: 'Unpaid', value: 'unpaid' },
        //     { text: 'Overdue', value: 'overdue' },
        //     { text: 'Deleted', value: 'deleted' }
        //   ]
        //   break
        case 'changeAffiliate':
          this.bulkEditingParams = await this.$store.dispatch('affiliates/all')
          break
        case 'changeCloudflare':
          this.bulkEditingParams = await this.$store.dispatch(
            'cloudflare/getAllCloudflareAccounts'
          )
          break
        case 'transferToNewServer':
          this.bulkEditingParams = await this.$store.dispatch(
            'hostingServers/getHostingServersList'
          )
          break
        case 'changeIP':
          // this.bulkEditingParams = []
          break
        case 'changeNSS':
          // this.bulkEditingParams = []
          break
        case 'setDefaultNSS':
          this.bulkEditingConfirm()
          this.bulkEditingSelectedParam = 'none'
          break
        case 'ssl':
          this.bulkEditingParams = [
            { value: 'full', text: 'Enable' },
            { value: 'off', text: 'Disable' }
          ]
          break
        case 'moveToTrash':
          this.bulkEditingConfirm()
          this.bulkEditingSelectedParam = 'none'
          break
        case 'moveFromTrash':
          this.bulkEditingConfirm()
          this.bulkEditingSelectedParam = 'none'
          break
        case 'sync':
          this.bulkEditingConfirm()
          this.bulkEditingSelectedParam = 'none'
          break
      }
    },
    bulkEditingSelectedParam(val, old) {
      if (val !== undefined && val !== old) {
        this.$nextTick(() => {
          if (typeof val === 'string') {
            this.bulkEditingSelectedParam = val.trim()
          }
        })
      }
    }
  },
  async mounted() {
    this.title = `${this.$t('domains.title')} — ${this.getStatusText}`

    this.initBulkEditing()

    this.filterItems.servers = await this.$store.dispatch(
      'hostingServers/getHostingServersList'
    )
    this.filterItems.providers = await this.$store.dispatch(
      'domainsProviders/getDomainsProvidersList'
    )
    this.filterItems.cf = await this.$store.dispatch(
      'cloudflare/getAllCloudflareAccounts'
    )
    this.filterItems.teams = await this.$store.dispatch('affiliatesTeams/all')

    this.tableHeaders = [
      {
        text: this.$t('domains.table.name'),
        align: 'start',
        value: 'name'
        // width: '350px'
      },
      { text: this.$t('domains.table.owner'), value: 'aff.team' },
      { text: this.$t('domains.table.registrar'), value: 'provider.name' },
      { text: this.$t('domains.table.hosting'), value: 'srv.name' },
      { text: this.$t('domains.table.cloudflare'), value: 'cf.email' },
      { text: this.$t('domains.table.ssl'), value: 'ssl', align: 'center' }
    ]

    if (this.actionsDisplayMethod === 'list') {
      const _actionsList = [
        {
          icon: 'webpack',
          text: this.$t(
            'domains.table.actionsList.domainProvider.domainProvider'
          ),
          has: 'domain_provider_id',
          children: [
            {
              icon: 'dns-outline',
              text: this.$t(
                'domains.table.actionsList.domainProvider.changeNSS'
              ),
              disabled: (i) => {
                return !i.domain_provider_id
              },
              fn: (i) => {
                this.nssDialog = true
                this.nssEditedItem = Object.assign(
                  {},
                  {
                    id: i.domain_provider_id,
                    domain: i.name,
                    ns_records: i.ns_records
                  }
                )
              }
            },
            {
              icon: 'folder-multiple-plus-outline',
              text: this.$t(
                'domains.table.actionsList.domainProvider.createDNSRecord'
              ),
              disabled: (i) => {
                return i.is_cloudflare_active === true
              },
              fn: (i) => {
                this.dnsDialog = true
                this.dnsEditedItem = Object.assign(
                  {},
                  {
                    id: i.domain_provider_id,
                    domain: i.name
                  }
                )
              }
            },
            {
              icon: 'sync',
              text: this.$t('domains.table.actionsList.domainProvider.sync'),
              disabled: (i) => {
                return !i.domain_provider_id
              },
              fn: async (i) => {
                // this.$set(i, 'loading', true)
                try {
                  const response = await this.$store.dispatch(
                    'domainsProviders/domainSync',
                    {
                      id: i.domain_provider_id,
                      name: i.name
                    }
                  )
                  if (response.success === true) {
                    await this.$toast.success(
                      this.$t('domains.sync.sendRequestSuccess')
                    )
                  } else {
                    await this.$toast.error(response.errors)
                  }
                } catch (e) {
                  console.log(e)
                } finally {
                  // this.$set(i, 'loading', false)
                }
              }
            },
            {
              icon: 'key-chain',
              text: this.$t(
                'domains.table.actionsList.domainProvider.credentials'
              ),
              can: 'domains.providers.auth',
              has: 'domain_provider_id',
              fn: async (i) => {
                await this.getCredentials(
                  i.domain_provider_id,
                  'domainsProvider'
                )
              }
            }
          ]
        },
        {
          icon: 'cloud-outline',
          text: this.$t('domains.table.actionsList.cloudflare.cloudflare'),
          has: 'cloudflare_id',
          children: [
            {
              icon: 'web',
              text: this.$t('domains.table.actionsList.cloudflare.editZone'),
              disabled: (i) => {
                return !i.cloudflare_id
              },
              fn: async (i) => {
                this.cloudflareZoneEditedItem = Object.assign(
                  {},
                  {
                    id: i.cloudflare_id,
                    zoneId: i.cloudflare_zone_id,
                    name: i.name,
                    ip: i.address_record || '',
                    ssl: i.cloudflare_ssl || '',
                    alwUseHttps: i.cloudflare_always_use_https || false,
                    devMode: i.cloudflare_dev_mode || false,
                    autoHttpsRewrite:
                      i.cloudflare_automatic_https_rewrites || false
                  }
                )
                this.cloudflareZoneDialog = true
              }
            },
            {
              icon: 'file-tree',
              text: this.$t(
                'domains.table.actionsList.cloudflare.createDNSRecord'
              ),
              disabled: (i) => {
                return !i.cloudflare_id
              },
              fn: (i) => {
                this.cloudflareDnsEditedItem = Object.assign(
                  {},
                  {
                    id: i.cloudflare_id,
                    zoneId: i.cloudflare_zone_id,
                    name: '',
                    type: 'A',
                    content: '',
                    proxied: true
                  }
                )
                this.cloudflareDnsDialog = true
              }
            },
            {
              icon: 'weather-cloudy-clock',
              text: this.$t(
                'domains.table.actionsList.cloudflare.clearZoneCache'
              ),
              disabled: (i) => {
                return !i.cloudflare_id
              },
              fn: async (i) => {
                this.$set(i, 'loading', true)
                try {
                  const response = await this.$store.dispatch(
                    'cloudflare/purgeCache',
                    {
                      id: i.cloudflare_id,
                      zoneId: i.cloudflare_zone_id
                    }
                  )
                  if (response.success === true) {
                    // await this.$store.commit('__domains.cloudflare/saveItem', response.data.result, { root: true })
                    await this.$toast.success(
                      this.$t('domains.cloudflare.zones.purgeCache.success')
                    )
                  } else {
                    await this.$toast.error(response.errors)
                  }
                } catch (e) {
                  console.log(e)
                  throw e
                } finally {
                  this.$set(i, 'loading', false)
                }
              }
            },
            {
              icon: 'wrench-clock',
              text: this.$t('domains.table.actionsList.cloudflare.devMode'),
              customText: (i) =>
                i.cloudflare_dev_mode === true
                  ? this.$t(
                    'domains.table.actionsList.cloudflare.disableDevMode'
                  )
                  : this.$t(
                    'domains.table.actionsList.cloudflare.enableDevMode'
                  ),
              disabled: (i) => {
                return !i.cloudflare_id
              },
              fn: async (i) => {
                this.$set(i, 'loading', true)
                try {
                  const value = i.cloudflare_dev_mode === false ? 'on' : 'off'
                  const response = await this.$store.dispatch(
                    'cloudflare/setDevMode',
                    {
                      id: i.cloudflare_id,
                      zoneId: i.cloudflare_zone_id,
                      value
                    }
                  )
                  if (response.success === true) {
                    this.$set(i, 'cloudflare_dev_mode', value === 'on')
                    await this.$toast.success(
                      this.$t('domains.cloudflare.zones.devMode.success')
                    )
                  } else {
                    await this.$toast.error(response.errors)
                  }
                } catch (e) {
                  console.log(e)
                  throw e
                } finally {
                  this.$set(i, 'loading', false)
                }
              }
            },
            {
              icon: 'sync',
              text: this.$t('domains.table.actionsList.cloudflare.sync'),
              disabled: (i) => {
                return !i.cloudflare_id
              },
              fn: async (i) => {
                this.$set(i, 'loading', true)
                try {
                  await this.$store.dispatch('cloudflare/zoneSync', {
                    id: i.cloudflare_id,
                    zoneId: i.cloudflare_zone_id,
                    name: i.name
                  })
                } catch (e) {
                  console.log(e)
                  throw e
                } finally {
                  // this.$set(i, 'loading', false)
                }
              }
            },
            {
              icon: 'key-chain',
              text: this.$t('domains.table.actionsList.cloudflare.credentials'),
              can: 'domains.cloudflare.auth',
              has: 'cloudflare_id',
              fn: async (i) => {
                await this.getCredentials(i.cloudflare_id, 'cloudflare')
              }
            }
          ]
        },
        {
          icon: 'server-network',
          text: this.$t(
            'domains.table.actionsList.hostingServer.hostingServer'
          ),
          has: 'hosting_server_id',
          children: [
            {
              icon: 'tune-vertical',
              text: this.$t(
                'domains.table.actionsList.hostingServer.serverStatusInfo.serverStatusInfo'
              ),
              disabled: (i) => i.srv.with_cp !== true,
              fn: (i) => {},
              children: [
                {
                  icon: 'cpu-64-bit',
                  text: this.$t(
                    'domains.table.actionsList.hostingServer.serverStatusInfo.cpuStatus'
                  ),
                  fn: async (i) => {
                    this.$set(i, 'loading', true)
                    try {
                      await this.getServerInfo(i.hosting_server_id, 'cpu')
                    } catch (e) {
                      console.log(e)
                      throw e
                    } finally {
                      this.$set(i, 'loading', false)
                    }
                  }
                },
                {
                  icon: 'memory',
                  text: this.$t(
                    'domains.table.actionsList.hostingServer.serverStatusInfo.memoryStatus'
                  ),
                  fn: async (i) => {
                    this.$set(i, 'loading', true)
                    try {
                      await this.getServerInfo(i.hosting_server_id, 'memory')
                    } catch (e) {
                      console.log(e)
                      throw e
                    } finally {
                      this.$set(i, 'loading', false)
                    }
                  }
                },
                {
                  icon: 'harddisk',
                  text: this.$t(
                    'domains.table.actionsList.hostingServer.serverStatusInfo.hardDiskStatus'
                  ),
                  fn: async (i) => {
                    this.$set(i, 'loading', true)
                    try {
                      await this.getServerInfo(i.hosting_server_id, 'disk')
                    } catch (e) {
                      console.log(e)
                      throw e
                    } finally {
                      this.$set(i, 'loading', false)
                    }
                  }
                }
              ]
            },
            {
              icon: 'account-switch',
              text: this.$t(
                'domains.table.actionsList.hostingServer.changeSiteOwner'
              ),
              disabled: (i) => i.srv.with_cp !== true,
              fn: (i) => {
                this.changeDomainOwnerEditedItem = {
                  id: i.hosting_server_id,
                  domain: i.name,
                  currentOwner: i.hosting_server_user
                }
                this.changeDomainOwnerDialog = true
              }
            },
            {
              icon: 'bank-transfer',
              text: this.$t(
                'domains.table.actionsList.hostingServer.transferTo'
              ),
              disabled: (i) => i.srv.with_cp !== true,
              // disabled: () => true,
              fn: (i) => {
                this.domainTransferEditedItem = {
                  domain: i.name,
                  id: i.hosting_server_id
                }
                this.domainTransferDialog = true

                // UPDATING ITEM AFTER TRANSFER SITE
                this.$eventBus.$on('updateDomainData', (data) => {
                  Object.keys(data).map((k) => {
                    if (!this.$helper.has(i, k)) {
                      i[k] = null
                    }
                    this.$set(i, k, data[k])
                    return k
                  })
                  this.$eventBus.$off('updateDomainData')
                })
              }
            },
            {
              icon: 'sync',
              text: this.$t('domains.table.actionsList.hostingServer.sync'),
              disabled: (i) => i.srv.with_cp !== true,
              fn: async (i) => {
                this.$set(i, 'loading', true)
                try {
                  await this.$store.dispatch('hostingServers/domainSync', {
                    id: i.hosting_server_id,
                    domain: i.name
                  })
                } catch (e) {
                  console.log(e)
                  throw e
                } finally {
                  this.$set(i, 'loading', false)
                }
              }
            },
            {
              icon: 'key-chain',
              text: this.$t(
                'domains.table.actionsList.hostingServer.credentials'
              ),
              can: 'hosting.servers.auth',
              has: 'hosting_server_id',
              fn: async (i) => {
                await this.getCredentials(i.hosting_server_id, 'hostingServer')
              }
            },
            {
              icon: 'web-remove',
              text: this.$t('Delete domain'),
              color: 'red',
              can: 'hosting.servers.remove',
              has: 'hosting_server_id',
              disabled: (i) => {
                return (
                  !i.name || !i.hosting_server_id || !i.hosting_server_user
                )
              },
              fn: async (i) => {
                if (
                  await this.$refs.confirmationDialog.open(
                    // Title
                    `Delete domain «${i.name}»`,
                    // Desc
                    `Are you sure you want to remove the «${i.name}» domain from server «${i.srv.ip}». ` +
                      'Please note that this action will permanently delete the file from the server.',
                    { color: 'red', width: 400 }
                  )
                ) {
                  this.$set(i, 'loading', true)
                  try {
                    await this.$store.dispatch('hostingServers/domainRemove', {
                      id: i.hosting_server_id,
                      domain: i.name,
                      user: i.hosting_server_user
                    })
                  } catch (e) {
                    console.log(e)
                    throw e
                  } finally {
                    this.$set(i, 'loading', false)
                  }
                }
              }
            }
          ]
        }
      ]
      this.actionsList.push(..._actionsList)
    }
    if (
      this.$can('domains.providers.create') ||
      this.$can('domains.providers.update')
    ) {
      this.formScheme = {
        name: {
          type: 'text',
          label: this.$t('domains.tableForm.name'),
          createRules: 'req',
          updateRules: [],
          forCreateOnly: true
        },
        domain_provider_id: {
          type: 'autocomplete',
          label: this.$t('domains.tableForm.domainProvider'),
          items: await this.$store.dispatch(
            'domainsProviders/getDomainsProvidersList'
          ),
          createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
          updateRules: [],
          forCreateOnly: true
        },
        hosting_server_id: {
          type: 'autocomplete',
          label: this.$t('domains.tableForm.hostingServer'),
          items: await this.$store.dispatch(
            'hostingServers/getHostingServersList'
          ),
          createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
          updateRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
          forEditedOnly: true,
          hint: 'Be careful, when you change the server, data is not transferred',
          md: 6
        },
        hosting_server_user: {
          type: 'autocomplete',
          label: this.$t('domains.tableForm.hostingUser'),
          items: ['user'],
          createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
          updateRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
          forEditedOnly: true,
          hint: 'Be careful, when you change the server, data is not transferred',
          md: 6
        },
        address_record: {
          type: 'text',
          items: [],
          label: this.$t('domains.tableForm.ip'),
          createRules: [
            (v) =>
              /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(
                v
              ) || 'IP Address is invalid'
          ], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
          updateRules: [
            (v) =>
              /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(
                v
              ) || 'IP Address is invalid'
          ],
          forEditedOnly: true,
          readonly: true,
          clearable: false,
          md: 12
        },
        affiliate_id: {
          type: 'autocomplete',
          label: this.$t('domains.tableForm.affiliate'),
          items: await this.$store.dispatch('affiliates/all'),
          createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
          updateRules: [],
          md: 6
        },
        cloudflare_id: {
          type: 'autocomplete',
          ref: 'cfid',
          label: this.$t('domains.tableForm.cloudflare'),
          items: await this.$store.dispatch(
            'cloudflare/getAllCloudflareAccounts'
          ),
          createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
          updateRules: [],
          forEditedOnly: true,
          md: 6
        },
        ns_records: {
          type: 'combobox',
          ref: 'nss',
          smallChips: true,
          chips: true,
          multiple: true,
          items: this.editedItem.ns_records,
          label: this.$t('domains.tableForm.nss'),
          createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
          updateRules: 'req',
          forEditedOnly: true,
          md: 12
        }
      }
    } else {
      this.formScheme = {}
    }
  },
  methods: {
    async getCredentials(id, from) {
      let actionFn
      switch (from) {
        case 'domainsProvider':
          if (!this.$can('domains.providers.auth')) {
            return
          }
          actionFn = 'domainsProviders/getDomainsProviderCredentials'
          break
        case 'cloudflare':
          if (!this.$can('domains.cloudflare.auth')) {
            return
          }
          actionFn = 'cloudflare/getCloudflareCredentials'
          break
        case 'hostingServer':
          if (!this.$can('hosting.servers.auth')) {
            return
          }
          actionFn = 'hostingServers/getHostingServerCredentials'
          break
      }
      const result = await this.$store.dispatch(actionFn, { id })
      if (result) {
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title: this.$t('dialog.credentials'),
          contentType: 'code',
          content: JSON.stringify(result, null, 2),
          maxWidth: 500,
          maxHeight: 600,
          withActionButtons: false
        })
      }
    },
    async getServerInfo(id, what) {
      let fn
      let title
      switch (what) {
        case 'cpu':
          fn = 'getServerCpuStatus'
          title = 'Server CPU Status'
          break
        case 'memory':
          fn = 'getServerMemoryStatus'
          title = 'Server Memory Status'
          break
        case 'disk':
          fn = 'getServerDiskStatus'
          title = 'Server Disk Status'
          break
      }
      const response = await this.$store.dispatch(`hostingServers/${fn}`, {
        id
      })
      if (response.success === true) {
        let maxWidth = 800
        let str = ''
        if (response.result instanceof Array) {
          response.result.map((row) => {
            str += row.join('\t') + '\n'
            return row
          })
        } else {
          str = JSON.stringify(response.result, null, 2)
          maxWidth = 500
        }
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title,
          contentType: 'code',
          content: str,
          maxWidth,
          maxHeight: 600,
          withActionButtons: false
        })
      } else {
        await this.$toast.error(response.errors)
      }
    },
    async sync(e) {
    },
    handleChange(event) {
      this.editedItem = event
    },
    async handleDialog(event) {
      if (event.dialog === true) {
        await this.formSchemeServer(
          event.item.hosting_server_id,
          event.item.hosting_server_user,
          event.item.hosting_server_ip
        )
      }
    },
    handleSelected(selected) {
      this.selected = selected
    },
    openWindow(domain) {
      window.open(`http://${domain}`, '_blank')
    },
    nssDialogClose() {
      this.nssDialog = false
      this.nssEditedItem = Object.assign(
        {},
        {
          id: '',
          domain: '',
          ns_records: []
        }
      )
    },
    closeDnsDialog() {
      this.dnsDialog = false
      this.dnsEditedItem = {
        id: '',
        domain: ''
      }
    },
    closeCloudflareZoneDialog() {
      this.cloudflareZoneDialog = false
      this.cloudflareZoneEditedItem = {}
    },
    closeCloudflareDnsDialog() {
      this.cloudflareDnsDialog = false
      this.cloudflareDnsEditedItem = {}
    },
    changeDomainOwnerDialogClose() {
      this.changeDomainOwnerDialog = false
      this.changeDomainOwnerEditedItem = {}
    },
    domainTransferDialogClose() {
      this.domainTransferDialog = false
      this.domainTransferEditedItem = {}
    },
    bulkEditingConfirm(e) {
      if (
        this.bulkEditingAction === 'changeIP' ||
        this.bulkEditingAction === 'changeNSS'
      ) {
        if (!this.bulkEditingSelectedParam) {
          return false
        }
      }
      this.bulkEditingConfirmDialog = true
    },
    async bulkEditing() {
      const ids = Object.keys(this.selected).map((key) => {
        this.$store.commit(`__${this.resource}/setCheckedItems`, {
          item: this.selected[key],
          value: false
        })
        return this.selected[key]._id
      })
      this.bulkEditingConfirmLoading = true
      try {
        const data = {
          action: this.bulkEditingAction,
          value: this.bulkEditingSelectedParam,
          ids
        }
        await this.$store.dispatch('domains/bulkEditing', data)
        this.bulkEditingConfirmDialogClose()
      } catch (e) {
        console.log(e)
      } finally {
        this.bulkEditingConfirmLoading = false
        // this.unselecting(ids)
      }
    },
    bulkEditingConfirmDialogClose() {
      this.bulkEditingConfirmDialog = false
      this.bulkEditingAction = ''
      this.bulkEditingSelectedParam = null
      this.bulkEditingParams = []
    },
    handleCurrentItems(items) {
      if (items.length > 0) {
        this.currentItems = items
      } else {
        this.currentItems = []
      }
    },
    handleChangeItems(data) {},
    domainDetailsExpand(item, e) {
      e.preventDefault()
      const opened = item.openedDomainInfo
      this.$set(item, 'openedDomainInfo', false)
      this.$set(item, 'domainInfoX', e.clientX)
      this.$set(item, 'domainInfoY', e.clientY)
      this.$nextTick(() => {
        this.$set(item, 'openedDomainInfo', !opened)
      })
    },
    hasError(item) {
      return (
        !Array.isArray(item.ns_records) ||
        !Array.isArray(item.ns_records_real) ||
        item.ns_records.length < 1 ||
        !this.$helper.compareTwoArrays(item.ns_records, item.ns_records_real) ||
        !item.address_record ||
        item.address_record !== item.address_record_real
      )
    },
    itemClassFn(item) {
      let classes
      if (this.getLockedEntities.indexOf(item._id) > -1) {
        classes += ' blue-grey lighten-5 loading-background '
      }
      if (this.hasError(item)) {
        classes += ' deep-orange lighten-5 '
      }
      return classes
      // let classes
      // if (this.hasError(item)) {
      //   classes += ' red lighten-5 '
      // }
      // return classes
    },
    async domainDnsInfo(item) {
      const result = {
        [item.name]: {
          DNS: {
            A: {
              DB: item.address_record || '',
              REAL: item.address_record_real || ''
            },
            NSS: {
              DB: item.ns_records,
              REAL: item.ns_records_real || []
            }
          }
        }
      }
      await this.$store.dispatch('dialog/dialog', {
        show: true,
        title: 'Domain DNS records',
        contentType: 'code',
        content: JSON.stringify(result, null, 2),
        maxWidth: 500,
        maxHeight: 650,
        withActionButtons: false
      })
    },
    async syncDomainDns(item) {
      try {
        await this.$store.dispatch('domains/syncDomainDns', item._id)
      } catch (e) {
        await this.$toast.error(e.response.data.message)
      }
    },
    initBulkEditing() {
      switch (this.getStatus) {
        case 'active':
        case 'withErrors':
        case 'draft':
          this.bulkEditingActionsList = [
            // { value: 'changeStatus', text: this.$t('domains.bulkActions.actionsList.changeStatus') },
            {
              value: 'sync',
              text: this.$t('domains.bulkActions.actionsList.sync')
            },
            {
              value: 'changeAffiliate',
              text: this.$t('domains.bulkActions.actionsList.changeAffiliate')
            },
            {
              value: 'changeCloudflare',
              text: this.$t('domains.bulkActions.actionsList.changeCloudflare')
            },
            {
              value: 'transferToNewServer',
              text: this.$t(
                'domains.bulkActions.actionsList.transferToNewServer'
              )
            },
            {
              value: 'changeIP',
              text: this.$t('domains.bulkActions.actionsList.changeIP')
            },
            {
              value: 'changeNSS',
              text: this.$t('domains.bulkActions.actionsList.changeNSS')
            },
            {
              value: 'setDefaultNSS',
              text: this.$t('domains.bulkActions.actionsList.setDefaultNSS')
            },
            {
              value: 'moveToTrash',
              text: this.$t('domains.bulkActions.actionsList.moveToTrash')
            },
            {
              value: 'ssl',
              text: this.$t('domains.bulkActions.actionsList.ssl')
            }
            // { value: 'moveToTrash', text: this.$t('domains.bulkActions.actionsList.moveToTrash') }
          ]
          break
        case 'deleted':
          this.bulkEditingActionsList = [
            {
              value: 'moveToTrash',
              text: this.$t(
                'domains.bulkActions.actionsList.deletePermanently'
              )
            },
            {
              value: 'moveFromTrash',
              text: this.$t('domains.bulkActions.actionsList.undelete')
            }
          ]
          break
        case 'all':
          this.bulkEditingActionsList = [
            {
              value: 'sync',
              text: this.$t('domains.bulkActions.actionsList.sync')
            },
            {
              value: 'changeAffiliate',
              text: this.$t('domains.bulkActions.actionsList.changeAffiliate')
            },
            {
              value: 'moveToTrash',
              text: this.$t('domains.bulkActions.actionsList.moveToTrash')
            }
          ]
          break
        case 'pendingRegistration':
        case 'pendingSettings':
          this.bulkEditingActionsList = [
            {
              value: 'moveToTrash',
              text: this.$t('domains.bulkActions.actionsList.moveToTrash')
            }
          ]
          break
        default:
          this.bulkEditingActionsList = []
          break
      }

      if (
        this.getStatus === 'deleted' ||
        this.getStatus === 'pendingRegistration' ||
        this.getStatus === 'pendingSettings'
      ) {
        this.actionsDisplayMethod = 'buttons'
        this.allowDefaultActions = false
      } else {
        this.actionsDisplayMethod = 'list'
        this.allowDefaultActions = true
      }
    },
    // unselecting(ids) {
    //   return ids.length > 0 ? ids.map((key) => {
    //     const selectedItem = this.items.find((s) => s._id === key)
    //     if (selectedItem > -1) {
    //       this.$store.commit(`__${this.resource}/setCheckedItems`, { item: selectedItem, value: false })
    //     }
    //   }) : []
    // }
    removeAllDraftConfirm() {
      this.removeAllDraftConfirmDialog = true
    },
    async removeAllDraft() {
      this.removeAllDraftLoading = true
      try {
        await this.$store.dispatch('domains/removeAllDraft')
      } catch (e) {
        await this.$toast.error(e.response.data.message)
      } finally {
        this.removeAllDraftLoading = false
        this.removeAllDraftConfirmDialogClose()
      }
    },
    removeAllDraftConfirmDialogClose() {
      this.removeAllDraftConfirmDialog = false
    },
    //
    removePermanently(item) {
      this.$refs.resourceTable.removeItem(item)
    },
    async removePermanentlyConfirm() {
      await this.$store.dispatch(
        '__domains.management/remove',
        this.bulkRemoving === true ? this.selected : this.editedItem
      )
      this.removePermanentlyConfirmClose()
    },
    removePermanentlyConfirmClose() {
      this.removePermanentlyConfirmDialog = false
    },
    //
    async undelete(item) {
      this.undeleteLoading = true
      try {
        await this.$store.dispatch('domains/undelete', { id: item.id })
      } catch (e) {
        await this.$toast.error(e.response.data.message)
      } finally {
        this.undeleteLoading = false
        // this.undeleteConfirmDialogClose()
      }
    },
    changeFilter(e = {}) {
      if (e !== null && e.target !== undefined) {
        if (e.keyCode === 13) {
          return this.save()
        }
      }
      this.$emit('changeFilter', this.filter)
    },
    async getCFAccounts() {
      return this.$store.dispatch('cloudflare/getAllCloudflareAccounts')
    },
    async formSchemeServer(id) {
      if (!id) {
        this.editedItem.hosting_server_id = ''
        this.editedItem.hosting_server_user = ''
        this.formScheme.hosting_server_user.disabled = true

        // this.editedItem.address_record = ''
        this.formScheme.address_record.type = 'text'
        this.formScheme.address_record.readonly = false
        return false
      }
      const hostingServerIp = await this.$store.dispatch(
        'hostingServers/getHostingServerIp',
        { id }
      )
      if (hostingServerIp) {
        Object.assign(this.formScheme.address_record, {
          type: 'select',
          items: hostingServerIp
        })
        this.editedItem.address_record = hostingServerIp[0]
      }
      if (!this.editedItem.hosting_server_user) {
        this.editedItem.hosting_server_user = 'user'
      }
      const hostingServerUsers = await this.$store.dispatch(
        'hostingServers/getHostingServerUsers',
        { id }
      )
      if (hostingServerUsers) {
        Object.assign(this.formScheme.hosting_server_user, {
          type: 'select',
          disabled: false,
          items: hostingServerUsers
        })
      }
    }
  }
}
</script>

<style lang="scss">
.domain-dns-settings-menu {
}

.chip-input-red input {
  font-size: 12px;
  color: #f44336 !important;
  padding-top: 15px !important;
}

.v-input--selection-controls {
  margin-top: 0;
}
</style>
